import React from 'react';
import './Sidebar.css';

function Sidebar(props) {
    return (
      <div className="Sidebar">
        <img className="sidebarImage" src={process.env.PUBLIC_URL + '/avatao.png'} alt="Avatao logo"/>
        {props.availableServices.map(function(service, i){
          return(<button
            key={service.name}
            className={"sidebarButton " + ((props.activeService.name === service.name) ? "active" : "")}
            onClick={() => props.updateActiveService(service)}>
              {service.name}
            </button>);
        })}
        <a href="https://avatao.github.io/openapi-spec/avatao-openapi-master.zip">
          <button className="sidebarButton">
            Download ZIP
          </button>
        </a>
      </div>
    );
}

export default Sidebar;
