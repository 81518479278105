import './App.css';
import './Sidebar.js';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import Sidebar from './Sidebar.js';
import React from 'react';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.updateActiveService = this.updateActiveService.bind(this)
    this.state = {
      availableServices: [],
      activeService: {
        "name": "",
        "specUrl": ""
      }
    }
  }

  componentDidMount() {
    fetch("https://avatao.github.io/openapi-spec/available_specs.json")
      .then(res => res.json())
      .then((result) => {
        for (const [key, value] of Object.entries(result)) {
          if (value.includes("master")) {
            this.setState({
              availableServices: this.state.availableServices.concat({
                "name": key,
                "specUrl": "https://avatao.github.io/openapi-spec/spec/" + key + "/master/openapi.yaml"
              })
            })
          }
        }
        this.setState({
          activeService: this.state.availableServices[0]
        })
      })
  }

  updateActiveService(service) {
    this.setState({
      activeService: service
    })
  }

  render() {
    return (
      <div className="App">
        <Sidebar availableServices={this.state.availableServices} activeService={this.state.activeService} updateActiveService={this.updateActiveService} />
        <div className="content">
          <SwaggerUI url={this.state.activeService.specUrl}/>
        </div>
      </div>
    );
  }
}

export default App;
